import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { LightParagraph } from './index';
import Header from '../components/Header';

import {
  SectionComponent,
  HeadlineComponent,
  ButtonComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ mg }) => mg || '0.5rem 2rem'};
  padding: ${({ pd }) => pd || '0'};
`;

export const Card = styled.div`
  display: flex;
  margin: 3rem 1.5rem;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  border-radius: 5px;
  height: 260px;

  .logo {
    border-left: 3px solid ${({ theme }) => theme.colors.green};
    border-radius: 5px 0px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media screen and (max-width: 768px) {
      border: none;
    }
  }

  .content {
    flex: 1.6;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 2rem;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 0px 5px 5px 0px;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 2rem;
    }
  }

   @media screen and (max-width: 768px) {
    flex-direction: column;
    border: 1px solid #d1d1d1;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 520px;
  }
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: center;
  padding: 1rem 2rem;
`;

export const FooterButton = styled.div`
  padding-top: 1.5rem;
`;

function PartnersPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "partners-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo1: file(relativePath: { eq: "logo-casinoware.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo2: file(relativePath: { eq: "logo-southern.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo3: file(relativePath: { eq: "logo-apt.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo4: file(relativePath: { eq: "logo-check.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Partners"
        link="https://teampoker.com/partners"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header
        image={data.background.childImageSharp.fluid}
        text="PARTNERS & CLIENTS"
        hasButton
        buttonText="Become a Partner"
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Become a Team Poker® Partner</HeadlineComponent>
        <ParagraphComponent size="1.2rem">
          Become a Team Poker® Partner and join the Poker Sports Model where
          both Individuals and Teams compete for cash, glory and fame!
        </ParagraphComponent>
        <Card>
          <div className="logo">
            <Img
              style={{ display: 'block' }}
              fixed={data.logo1.childImageSharp.fixed}
            />
          </div>
          <div className="content">
            <HeadlineComponent color="white">CasinoWare</HeadlineComponent>
            <LightParagraph>
              CasinoWare is the ultimate poker tournament, cash game and casino
              management software package. You will not find a more
              professional, feature rich, easy to use tool for running your
              tournaments.
            </LightParagraph>
            <a href="https://casinoware.net" target="_blank" rel="noreferrer">

            <ButtonComponent>Visit CasinoWare</ButtonComponent>
            </a>
          </div>
        </Card>
        <ParagraphComponent size="1.2rem">
          If you run a poker room or want to start one just download our
          existing partners Poker Room Software and you can be playing Team
          Poker® Tournaments immediately.
        </ParagraphComponent>
        <Card>
          <div className="logo">
            <Img
              style={{ display: 'block' }}
              fixed={data.logo3.childImageSharp.fixed}
            />
          </div>
          <div className="content">
            <HeadlineComponent color="white">
              Australian Poker Tour
            </HeadlineComponent>
            <LightParagraph>
              The Australian Poker Tour is a series of major poker tournaments
              conducted throughout Australia. Australian Poker Tour Events take
              place in some of the largest pubs and clubs in Australia’s largest
              cities.
            </LightParagraph>
            <a href="https://australianpokertour.com.au" target="_blank" rel="noreferrer">
            <ButtonComponent>Visit APTLive</ButtonComponent>
            </a>
          </div>
        </Card>
        <ParagraphComponent size="1.2rem">
          Team Poker® offers limited event licensing for single casino internal
          LIVE Poker Rooms now through our existing tournament software
          Partners.
        </ParagraphComponent>
        <Card>
          <div className="logo">
            <Img
              style={{ display: 'block' }}
              fixed={data.logo4.childImageSharp.fixed}
            />
          </div>
          <div className="content">
            <HeadlineComponent color="white">Check Raise</HeadlineComponent>
            <LightParagraph>
              Check Raise Poker is a series of major poker tournaments conducted
              throughout Australia. Team Poker® events has joined their schedule
              to bring the Poker Sports Model to their live events.
            </LightParagraph>
            <a href="https://www.facebook.com/Check-Raise-Poker-Queensland-645819679163825" target="_blank" rel="noreferrer">
            <ButtonComponent>Visit Check Raise</ButtonComponent>
            </a>
          </div>
        </Card>
        <ParagraphComponent size="1.2rem">
          If you are a poker software developer for either Brick and Mortar/
          Live Poker or an Online Poker Room, contact us about Licensing
          Requirements to bring the Poker Team Paradigm to your Room.
        </ParagraphComponent>
        <Card>
          <div className="logo">
            <Img
              style={{ display: 'block' }}
              fixed={data.logo2.childImageSharp.fixed}
            />
          </div>
          <div className="content">
            <HeadlineComponent color="white">
              Southern Poker Tour
            </HeadlineComponent>
            <LightParagraph>
              Southern Poker Tour was created and formed by four poker loving
              friend looking at creating a unique experience not regularly
              available and accessible to poker players in areas all over
              Southern Australia.
            </LightParagraph>
            <a href="https://southernpokertour.com.au" target="_blank" rel="noreferrer">
            <ButtonComponent>Visit Southern Poker Tour</ButtonComponent>
            </a>
          </div>
        </Card>
        <ParagraphComponent size="1.2rem">
          Become a Team Poker® Partner and join the Poker Sports Model where
          both Individuals and Teams compete for cash, glory and fame!
        </ParagraphComponent>
        <FooterButton>
        <Link to ="/contact">

          <ButtonComponent>Become a partner</ButtonComponent>
        </Link>
        </FooterButton>
      </SectionComponent>
    </Layout>
  );
}

export default PartnersPage;
